/**
 *  Bootstrap pull 3.x-1.1.1 for Twitter Bootstrap v3.
 *  
 *  To have pull-right and pull-left to Bootstrap v3 for xs, sm, md, lg media.
 *  This will help having some complex behavior for contents.
 *  https://github.com/Natshah/bootstrap-pull
 *
 */


@media (max-width: @screen-xs) {
  /** Pull left xs **/
  .pull-left-xs {
    float: left;
  }

  /** Pull right xs **/
  .pull-right-xs {
    float: right;
  }
}

@media (min-width: @screen-sm-min) {
  /** Pull left sm **/
  .pull-left-sm {
    float: left;
  }

  /** Pull right sm **/
  .pull-right-sm {
    float: right;
  }
}

@media (min-width: @screen-md-min) {
  /** Pull left **/
  .pull-left-md {
    float: left;
  }

  /** Pull right **/
  .pull-right-md {
    float: right;
  }
}

@media (min-width: @screen-lg-min) {
  /** Pull left **/
  .pull-left-lg {
    float: left;
  }

  /** Pull right **/
  .pull-right-lg {
    float: right;
  }
}
