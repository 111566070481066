@font-face {
	font-family: 'Gotham';  
	src: url('../fonts/gotham-bold.woff') format('woff');
	font-weight:bold;
}
@font-face{
	font-family: 'Gotham';
	src: url('../fonts/gotham-book.eot');
	src: url('../fonts/gotham-book.eot?#iefix') format('embedded-opentype'),
	url('../fonts/gotham-book.woff') format('woff'),
	url('../fonts/gotham-book.ttf') format('truetype'),
	url('../fonts/gotham-book.svg#webfont') format('svg');
	font-weight:normal;
}