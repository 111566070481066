//wizQ css here..
@footer-background-color: #838F98;
@green-color: #7bb0b8;
@pink-color: #f1aaa1;
@yellow-color: #efab2c;

.wq_footer {
    background-color: @footer-background-color;
    min-height: 50px;
}
.wq_footer .container {
    padding-top: 9px;
}
.wq_footer div{
    color: #ffffff;
    font-size: 12pt;
}
.wq_footer a{
    color: #ffffff;
}


.wq_main_menu li.active {
    border-color: #D3392B;

}

.wq_lang_bar li:last-child a:after { 
    content: ""; 
}

.wq_main_menu li:hover{
    border-color: @pink-color;  
}


.wq_content {
    padding-top: 50px;
}

#about .band{
    padding: 60px 0;
    background-color: #E0E0E0;

}
#about .band p {
    margin-bottom: 60px;
    font-weight: bold;
}
#about .container h3+p {
    margin-bottom: 100px;
}

#contact{
    background-color: @yellow-color;
}
#contact .panel h3+div{
    line-height: 1.16666667;//21/18;
}
#contact .contact_form {
    margin-top: 10px;
}
#contact #welcome {
    position: relative;
    margin-bottom: 40px;
}


#contact .red_star, .service .highlight, #solution .highlight{
    color: @brand-primary;
}

.service {
    min-height: 680px;
}
.service ul {
    padding: 0;
}
.home {
    height: 680px;
}
.service div div {
    position: relative;
}

#call_action.service, #call_action.home {
    height: 520px;
    padding: 150px 0;
}

#call_action.service h2+a, #call_action.home h2+a{
    margin-top: 85px;
}

#wizq_api {
    background: #e0e0e0 url("../images/wizq_api.png") no-repeat center bottom;
}
#wizq_api:lang(cn) {
    background: #e0e0e0 url("../images/wizq_api_cn.png") no-repeat center bottom;
}
#wizq_api:lang(jp) {
    background: #e0e0e0 url("../images/wizq_api_jp.png") no-repeat center bottom;
}

.service ul li{
    list-style: none;
    font-size: @font-size-small;
}
.green_bg {
    background: @green-color;
}
.pink_bg {
    background: @pink-color;
}
.yellow_bg {
    background: @yellow-color;
}
#distribution {
    padding-top: 20px;
}
#distribution a {
    margin-top: 10px;
}

#cs li img, #culturalization li img, #adv li img, #distribution li img{
    margin-bottom: 20px;
}




.testimonial {
    font-size: ceil((@font-size-base * 0.75));
    background-color: #51778c;    
    color: #FFFFFF;
    height: 457px;
    width: 280px;
    display: inline-block;
    padding: 25px;    
    vertical-align: top;

}
.testimonial p:first-child {
    display: block;
    min-height: 272px;
}

.testimonial .person {
    color: #efab2c;
    font-weight: bold;
}
#testimonial {
    padding: 20px 0px 85px 0px;

}
#testimonial h3 {
    color: #FFFFFF;
    margin: 30px 0;
}
#platform {
    padding-bottom: 30px;
}
#platform h3 {
    color: #333333;
    margin: 30px 0;
}
.platfoms {
    min-height: 240px;
}
.platfoms img {
    margin: 8px 5px;
}

#solution h2{
    margin: 100px 0 55px 0;
}
#solution a {
    margin-bottom: 100px;
}




#headline {
    background: url("../images/headline_bg.jpg") no-repeat top center;

}


#headline .people {
    background: url("../images/people.png") no-repeat bottom center;
    height: 600px;
}

.category {
    font-size: @font-size-tiny;
}

.btn-more {
    .button-variant(@btn-more-color; @btn-more-bg; @btn-more-border);
}
.people h1+a {
    margin-right: 100px;
}

.dev_problem {
    color: @brand-primary;
}
#games {
    margin-top: 50px;
}
.game {
    padding-bottom: 30px;
}

.game_info {
    border-top: 1px dotted #cccccc;
    padding: 7px 0;
}

.game a {
    color: #333333;
}

.game a:hover, .game a:focus{
    color: #333333;
    text-decoration: none;
}

:focus,
:active {
    outline: 0 !important;
}

#contact .panel div {
    font-size: @font-size-small;
}
.service_take_away+img {
    margin: 0 auto;
}
@media (min-width: @screen-xs-min) {
    .wq_footer .logo{
        max-height: 32px;
    }
    .footer_menu {
        display: block;
    }
    .footer_menu li {
        float: none;
    }
    .wq_footer li a:hover, .wq_footer li a:focus {
        background-color: lighten(@footer-background-color, 15%);
    }
    .wq_menu .logo{
        max-height: 52px;
    }
    .wq_main_menu {
        margin-top: 10px;
    }
    .wq_lang_bar li {
        padding: 0 15px;
    }
    .wq_lang_bar li a{
        padding: 10px 0;
    }
    .wq_lang_bar li a:after { 
        content: ""; 
    }
    .navbar-right .social_bar {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .navbar-right .social_bar a {
        margin-right: 10px;
    }
    .game_title {
        font-size: @font-size-small;
        margin: 7px 0;
    }
    .game_description {
        font-size: @font-size-tiny;
        min-height: 80px;
    }
    #contact #welcome .bubble{
        margin-top: -425px;
        margin-bottom: 500px;
        color: #594f56;
        font-size: @font-size-small;
    }
    .service li.point1, .service li.point2, .service li.point3 {
        display: block;
        margin: 0 auto;
    }
    .service_take_away {
        font-weight: bold;
        color: @brand-primary;
        margin-top: 50px;
        margin-bottom: 60px;
        font-size: @font-size-h3;
    }
    #offer h1{
        font-size: (@font-size-h2 * 0.9);  
    }

    #offer {
        background-color: #e0e0e0;
        height: 130px;   
    }
    #offer .arrow {
        margin-top: 13px;
        background-color: #d3392b;
        height: 85px;
        width: 85px;
        color: #FFFFFF;   
        display: inline-block;
        padding: 27px 0;
        font-size: @font-size-h2;
    }
    #cs li, #culturalization li, #adv li, #distribution li{
        min-height: 350px;
        text-align: center;
    }
    .testimonial {
        margin-bottom: 10px;
    }
    #headline h1 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .asia h3 {
        text-align: left;
        color: #333333;
    }
    .europe h3 {
        color: #333333;
        text-align: left;
    }
    .asia {
        min-height: 525px;
        margin-top: 40px;
    }

    .europe {
        height: 570px;
    }
}

@media (min-width: 340px) {
    #contact #welcome .bubble{
        margin-top: -470px;
        margin-bottom: 350px;
    }
}

@media (min-width: @screen-sm-min) {
    .wq_footer .logo{
        max-height: 32px;      
    }
    .wq_footer .footer_menu {
        margin-top: 5px;
        display: inline-block;
        float: none;
    }
    .wq_footer .footer_menu li {
        float: left;
    }
    .wq_footer .footer_menu li a:after { 
        content: " | "; 
    }
    .wq_footer .footer_menu li:last-child a:after { 
        content: ""; 
    }
    .wq_footer .footer_menu li a {
        padding: 0;
    }
    .wq_footer li a:hover, .wq_footer li a:focus {
        background-color: transparent;
    }
    .wq_menu .logo{
        margin-right: 10px;
        max-height: 80px;
        margin-top: 15px;
    }
    .wq_lang_bar li {
        padding: 0;
    }
    .wq_lang_bar li a{
        padding: 0;
    }
    .wq_lang_bar li a:after { 
        content: "|"; 
    }
    .navbar-right .social_bar {
        margin-top: 50px;
    }
    .navbar-right .social_bar a {
        margin-right: 0px;
    } 
    .wq_main_menu li {
        padding-top: 26px;
        border-bottom: 2px solid #594F56;
    }
    .game_title {
        font-size: @font-size-tiny;
        font-weight: bold;
        margin: 7px 0;
    }
    .game_description {
        font-size: @font-size-tiny;
        min-height: 140px;
    }

    #contact #welcome .bubble{
        margin-top: 0;
        margin-bottom: 0;
        position: absolute;
        float: left;
        top: 11.47%;
        left: 59.51%;
        font-size: @font-size-small;
    }
    .service li.point1, .service li.point2, .service li.point3 {
        display: block;
        margin: 0 auto;
    }
    #wizq_api {
        background-size:50%;
    }
    .service_take_away {
        font-weight: bold;
        color: @brand-primary;
        margin-top: 50px;
        margin-bottom: 60px;
        font-size: @font-size-h2;
    }
    #offer h1{
        font-size: @font-size-h1;  
    }
    #offer {
        background-color: #e0e0e0;
        height: 150px;   
    }
    #offer .arrow {
        margin-top: 13px;
        background-color: #d3392b;
        height: 85px;
        width: 85px;
        color: #FFFFFF;   
        display: inline-block;
        padding: 27px 0;
        font-size: @font-size-h2;
    }
    #cs li, #culturalization li, #adv li, #distribution li{
        width: 350px;
        min-height: 350px;
        text-align: center;
    }
    .asia h3 {
        text-align: left;
        color: #333333;
        margin-top: -10px;
    }
    .europe h3 {
        color: #333333;
        text-align: left;
        padding-top: 120px;
    }
    .asia {
        height: 525px;
        background: url("../images/asia.png") no-repeat;
        background-position: 30px 0px;
        text-align: right;
        margin-top: 80px;
        background-size: 495px 470px;
    }

    .europe {
        height: 570px;
        background: url("../images/europe.png") no-repeat;
        background-position: right top;
        background-size: 419px 513px;
        .pull-right();
    }
    #headline h1 {
        margin-top: 90px;
        margin-bottom: 65px;
    }
}
@media (min-width: @screen-md-min) {
    .wq_footer .logo{
        max-height: 32px;
        margin-right: 30px;
    }
    .copyright {
        margin-top:  6px;
    }

    .wq_main_menu li{
        min-width: 120px;
        text-align: center;
        border-bottom: 4px solid #594F56;
        margin-left: 20px;
        padding-top: 44px;
        font-size: @font-size-small;
    }
    .wq_lang_bar li {
        padding: 0;
    }
    .wq_lang_bar li a{
        padding: 0;
    }
    .wq_lang_bar li a:after { 
        content: "|"; 
    }
    .navbar-right .social_bar {
        margin-top: 50px;
    }
    .navbar-right .social_bar a {
        margin-right: 0px;
    }
    .wq_menu .logo{
        margin-right: 0px;
        max-height: 98px;
        margin-top: 20px;
    }
    .game_title {
        font-size: @font-size-small;
        margin: 7px 0;
    }
    .game_title {
        font-size: @font-size-tiny;
        font-weight: bold;
        margin: 7px 0;
    }
    .game_description {
        font-size: @font-size-tiny;
        min-height: 120px;
    }
    #contact #welcome .bubble{
        position: absolute;
        float: left;
        top: 11.47%;
        left: 59.51%;
    }

    .service li.point1 {
        position: absolute;
        float: left;
        top: 145px;
        left: 0;
        .clearfix();
    }
    .service li.point2 {
        position: absolute;
        float: left;
        top: 230px;
        left: 33%;//left: 355px;
    }
    .service li.point3 {
        position: absolute;
        float: left;
        top: 0;
        left: 66%;//710px;
    }
    .service li img {
        .scale(0.9);
    }
    #wizq_api {
        background-size:auto;
    }
    .asia h3 {
        text-align: left;
        color: #333333;
        margin-top: 20px;
    }
    .europe h3 {
        color: #333333;
        text-align: left;
        padding-top: 320px;
    }
    .asia {
        height: 525px;
        background: url("../images/asia.png") no-repeat;
        background-size: auto;
        background-position: 30px 0px;
        text-align: right;
        margin-top: 80px;
    }

    .europe {
        min-width: @screen-md-min;
        height: 570px;
        background: url("../images/europe.png") no-repeat;
        background-position: right top;
        margin-top: -210px;
        .pull-right();
    }
    .testimonial {
        margin-bottom: 0;
    }
    #headline h1 {
        margin-top: 120px;
        margin-bottom: 100px;
    }
}